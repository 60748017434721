import { List, Map } from 'immutable';

import { VARIATION_NOT_FOUND } from 'components/InstructionList/instructionListUtils';
import Variation from 'components/Variation';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { colorVariation, Variation as variationType } from 'utils/flagUtils';

const getVariationIndex = (variationID: string, variations: List<variationType>) =>
  variations.findIndex((v) => v._id === variationID);

export const RolloutDescription = ({
  contextKind,
  rolloutWeights,
  variations,
  bucketBy,
}: {
  contextKind?: string;
  rolloutWeights: Map<string, number>;
  variations: List<variationType>;
  bucketBy?: string;
}) => {
  const renderVariation = (index: number) => (
    <Variation
      className="u-ml-s"
      key={index}
      color={colorVariation(index)}
      value={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        variations.get(index)!.getDisplay() /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    />
  );

  let i = 0;
  const length = rolloutWeights.size;
  const rolloutContextKind = contextKind || USER_CONTEXT_KIND;
  const sortedRolloutWeights = rolloutWeights.sortBy((_value, variationID) => {
    const variationIndex = getVariationIndex(variationID, variations);
    if (variationIndex === -1) {
      return Infinity;
    }
    return variationIndex;
  });

  return (
    <span className="RolloutDescription">
      {sortedRolloutWeights
        .map((value: number, variationID: string) => {
          const variationIndex = getVariationIndex(variationID, variations);
          const displayedVariationIndex: number | string = variationIndex !== -1 ? variationIndex : VARIATION_NOT_FOUND;
          const percent = value / 1000;
          const additionalRolloutText = i++ !== length - 1 && ', ';
          return (
            <span key={variationID} className="RolloutDescription__item">
              {typeof displayedVariationIndex === 'number'
                ? renderVariation(displayedVariationIndex)
                : ` ${VARIATION_NOT_FOUND}`}
              <span className="u-ml-s">({percent}%)</span>
              {additionalRolloutText}
            </span>
          );
        })
        .valueSeq()}
      {bucketBy && ` bucketed by ${rolloutContextKind} attribute ${bucketBy}`}
    </span>
  );
};
